export const getFirebaseItem = (name, price, sku, quantity = 1) => {
  const baseData = {
    item_id: name,
    item_name: name,
    item_category: 'belt',
    price,
  }

  if (sku) {
    return {
      ...baseData,
      item_id: sku,
      quantity,
    };
  }

  return baseData
}

export const getViewedItem = (name, price, sku) => {
  return {
    currency: 'SEK',
    value: price,
    items: [getFirebaseItem(name, price, sku)],
  }
}

export const getFirebaseCart = (items, total) => {
  const firebaseItems = items.map((item) => getFirebaseItem(item.name, item.price, item.sku))
  return {
    currency: 'SEK',
    value: total,
    items: firebaseItems
  }
}
