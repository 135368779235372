import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Hamburger from './hamburger'

import logo from '../images/logo.svg'
import buckle from '../images/icons/buckle.svg'
import cart from '../images/cart.svg'
import { useDocumentScroll } from '../utilities/useDocumentScroll'
import { breakpointsDown, colors } from '../styling/constants'
import { Transition } from 'react-spring/renderprops'
import MobileMenu from './mobileMenu'

import Context from './Context'
import Cart from './cart/cart'
import { FormattedMessage } from 'react-intl'
import StickyFooter from './StickyFooter'

interface CollapsedProps {
  isCollapsed: boolean
  isOpen?: boolean
}

const MainWrapper = styled.div<CollapsedProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.2s ease-out, padding 0.2s ease-out;
  background-color: ${({ isCollapsed, isOpen }) =>
    isCollapsed || isOpen ? 'white' : 'transparent'};
`

const HeaderWrapper = styled.div`
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  padding: 1.3rem 5vw;
`

const Buckle = styled.img<CollapsedProps>`
  width: 2rem;
  position: absolute;

  transition: all 0.2s ease-out;
  top: ${({ isCollapsed }) => (!isCollapsed ? '-5rem' : '1.6rem')};
  opacity: ${({ isCollapsed }) => (!isCollapsed ? 0 : 1)};

  @media ${breakpointsDown.desktop} {
    display: none;
  }
`

const Logo = styled.img<CollapsedProps>`
  width: 15rem;

  transition: all 0.2s ease-out;
  position: absolute;
  top: ${({ isCollapsed }) => (isCollapsed ? '-5rem' : '2rem')};
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};

  @media ${breakpointsDown.desktop} {
    display: none;
  }
`

const MobileLogo = styled.img`
  width: 10rem;
  display: none;
  @media ${breakpointsDown.desktop} {
    display: inline-block;
  }
`

const LogoLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const LogoLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LinksContainer = styled.nav<CollapsedProps>`
  transition: margin-top 0.2s ease-out;
  margin-top: ${({ isCollapsed }) => (isCollapsed ? 0 : '2.7rem')};
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  }
  @media ${breakpointsDown.desktop} {
    display: none;
  }
`

const MenuItem = styled.li`
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  margin: 0 1.5rem;
`

const SideWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${breakpointsDown.wide} {
    width: 10%;
  }
  @media ${breakpointsDown.desktop} {
    width: 40%;
  }
`

const InfoWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${breakpointsDown.desktop} {
    width: 40%;
  }
`

const CartIcon = styled.img`
  width: 1.6rem;
`

const HamWrapper = styled.div`
  display: none;

  @media ${breakpointsDown.desktop} {
    display: inline-block;
    position: absolute;
    top: 0.9rem;
  }
`

const BuckleWrapper = styled.div`
  @media ${breakpointsDown.desktop} {
    display: none;
  }
`

const SendTo = styled.div`
  display: flex;
  flex-direction: row;
  p { 
    margin: 0;
  }
  @media ${breakpointsDown.desktop} {
    display: none;
  }
`

const CartContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
`

const UnstyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 3rem;

  @media ${breakpointsDown.wide} {
    margin-left: 1.5rem;
  }
`

const Select = styled.select`
  background: none;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  border: none;
  appearance: none;
  text-decoration: underline;
  margin-left: 0.3rem;

  @media ${breakpointsDown.tablet} {
    font-size: 14px;
  }
`

interface HeaderProps {
  locale: string
  menuLinks: Record<string, string>[]
}

const NewHeader: React.FC<HeaderProps> = ({ locale, menuLinks }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [isCollapsed, setIsCollapsed] = React.useState(false)

  useDocumentScroll(({ currentScrollTop }) => {
    if (currentScrollTop > 30) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  })

  return (
    <>
      <MainWrapper isCollapsed={isCollapsed} isOpen={menuOpen}>
        <HeaderWrapper>
          <SideWrapper>
            <BuckleWrapper>
              <Link to={locale !== 'sv' ? '/' + locale : '/'}>
                <Buckle
                  isCollapsed={isCollapsed}
                  src={buckle}
                  alt="Belt of Sweden buckle"
                />
              </Link>
            </BuckleWrapper>
            <HamWrapper onClick={() => setMenuOpen(!menuOpen)}>
              <Hamburger isOpen={menuOpen} />
            </HamWrapper>
          </SideWrapper>
          <LogoLinks>
            <LogoLink
              to={locale !== 'sv' ? '/' + locale : '/'}
              onClick={() => setMenuOpen(false)}
            >
              <MobileLogo src={logo} alt="Belt of Sweden logo" />
              <Logo
                isCollapsed={isCollapsed}
                src={logo}
                alt="Belt of Sweden logo"
              />
            </LogoLink>
            <LinksContainer isCollapsed={isCollapsed}>
              <ul>
                {menuLinks &&
                  menuLinks.map((link, i) => (
                    <MenuItem key={i}>
                      <Link to={link.link}>{link.name}</Link>
                    </MenuItem>
                  ))}
              </ul>
            </LinksContainer>
          </LogoLinks>
          <InfoWrapper>
            {locale !== 'sv' && (
              <Context.Consumer>
                {({ data, set }) => (
                  <SendTo>
                    <p>
                      <FormattedMessage id="index.dispatch-to" />
                    </p>
                    <Select
                      value={data.currency}
                      onChange={(e) =>
                        set({ ...data, currency: e.target.value })
                      }
                    >
                      <option value="usd">US</option>
                      <option value="eur">EU</option>
                      <option value="sek">SE</option>
                    </Select>
                  </SendTo>
                )}
              </Context.Consumer>
            )}
            <Context.Consumer>
              {({ data, set }) => (
                <UnstyledButton
                  onClick={() => set({ ...data, cartOpen: !data.cartOpen })}
                >
                  <CartIcon src={cart} alt="Cart" />
                </UnstyledButton>
              )}
            </Context.Consumer>
          </InfoWrapper>
        </HeaderWrapper>
        <Transition
          items={menuOpen}
          from={{ opacity: 0, height: 0 }}
          enter={{ opacity: 1, height: 'auto' }}
          leave={{ opacity: 0, height: 0 }}
        >
          {(show) =>
            show &&
            ((props) => (
              <div style={props}>
                <MobileMenu toggleMenu={setMenuOpen} menuLinks={menuLinks} />
              </div>
            ))
          }
        </Transition>
      </MainWrapper>
      <Context.Consumer>
        {({ data }) => {
          return (
            <CartContainer>
              <Transition
                items={data.cartOpen}
                from={{ opacity: 1, transform: 'translateX(100%)' }}
                enter={{ opacity: 1, transform: 'translateX(0%)' }}
                leave={{ opacity: 1, transform: 'translateX(100%)' }}
              >
                {(show) =>
                  show &&
                  ((props) => (
                    <div style={props}>
                      <Cart locale={locale} />
                    </div>
                  ))
                }
              </Transition>
            </CartContainer>
          )
        }}
      </Context.Consumer>
    </>
  )
}

export default NewHeader
