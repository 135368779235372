import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import './mobileMenu.scss'

const MenuItem = styled.li`
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  margin: 0.5rem 5vw;
`

class MobileMenu extends Component {
  render() {
    return (
      <div className="mobile-menu-wrapper">
        <ul>
            { this.props.menuLinks.map((link, i) =>
            <MenuItem onClick={this.props.toggleMenu} key={i}>
                <Link to={ link.link }>{ link.name }</Link>
            </MenuItem>
            )}
        </ul>
      </div>
    )
  }
}

export default MobileMenu