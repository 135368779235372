import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';
import { motion } from 'framer-motion'
import { colors, breakpointsDown } from '../styling/constants'

import seFlag from '../images/flag-se.png';
import usFlag from '../images/flag-us.png';
import euFlag from '../images/flag-eu.png';

const LocationContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
`

const LocationBox = styled.div`
  background-color: ${colors.lightGray};
  color: ${colors.darkGray};
  padding: 2rem;
  margin: 5vw;
  border-radius: 8px;
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.p`
  font-size: 1.6rem;
`

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20rem;
`

const Location = styled.button`
  border: 1px ${colors.darkGray} solid;
  border-radius: 12px;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;
  padding: 0.8rem 1.2rem;
  transition: 0.2s background-color ease-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 1rem;
    color: ${colors.darkGray};
    margin: 0 0 0 1.2rem;
    padding: 0;
  }
`

const Flag = styled.img`
  width: 1.7rem;
`

const regions = [
  {
    name: "United States",
    flag: usFlag,
    currency: "usd"
  },
  {
    name: "European Union",
    flag: euFlag,
    currency: "eur",
  },
  {
    name: "Sweden",
    flag: seFlag,
    currency: "sek",
  },
]

export const LocationSelector = ({set, clicked}) => {
  return (
    <LocationContainer exit={{ opacity: 0 }} transition={{
      type: 'spring',
      damping: 40,
      stiffness: 400,
    }}>
      <LocationBox>
        <Header>Please choose your region</Header>
        <Locations>
          {regions.map((region) => (
            <Location key={region.currency} onClick={(e) => {
              clicked(e)
              set({currency: region.currency})
            }}>
              <Flag src={region.flag} />
              <p>{region.name}</p>
            </Location>
          ))}
        </Locations>
      </LocationBox>
    </LocationContainer>
  )
}
