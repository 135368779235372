import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './button.scss'

import darkArrow from "../images/arrow-dark.svg"
import goldArrow from "../images/arrow-gold.svg"
import lightArrow from "../images/arrow-light.svg"

class Button extends Component {
  arrows = {
    dark: darkArrow,
    gold: goldArrow,
    light: lightArrow
  };

  render() {
    return (
      <div className="button-wrapper">
        { this.props.link ? (<Link to={this.props.link} className="button">
          { this.props.empty ? null: <img className="arrow" alt="Arrow" src={this.arrows[this.props.color]} /> }
          <span className="text">{ this.props.text }</span>
        </Link>) : (<div className="button">{ this.props.empty ? null: <img className="arrow" alt="Arrow" src={this.arrows[this.props.color]} /> }
        <span className="text">{ this.props.text }</span></div>) }
      </div>
    )
  }
}

Button.propTypes = {
  color: PropTypes.oneOf(['dark', 'light', 'gold']),
  text: PropTypes.string,
}

export default Button
