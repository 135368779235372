import React from 'react'
import styled from 'styled-components'
import { colors, breakpointsDown } from '../styling/constants'
import { Link } from 'gatsby';
import { motion } from 'framer-motion'

import cartClose from "../images/cart-close.svg"
import { FormattedMessage } from 'react-intl';

const CookieContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  background-color: ${colors.darkGray};
  color: ${colors.lightGray};
  padding: 2rem;
  border-radius: 8px;
  max-width: 40rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  margin-left: 2rem;
  z-index: 1000;
  
  p {
    flex-shrink: 2;
    margin: 0;
    padding: 0;
    
    a {
      text-decoration: underline;
    }
  }

  @media ${breakpointsDown.tablet} {
    bottom: 0.5rem;
    right: 0.5rem;
    margin-left: 0.5rem;
  }
`

const CloseButton = styled.button`
  margin: 0 0 0 1.5rem;
  padding: 0;
  background: transparent;
  border: none;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`

export const CookiePopup = ({clicked, locale}) => {
  return (
    <CookieContainer exit={{ y: 100, opacity: 0 }} transition={{
      type: 'spring',
      damping: 40,
      stiffness: 400,
    }}>
      <p>
        <FormattedMessage id="index.cookie-warning" /> <Link to={locale !== 'sv' ? '/' + locale + '/privacy' : '/privacy'}><FormattedMessage id="index.cookie-link" /></Link>
      </p>
      <CloseButton onClick={(e) => clicked(e)}>
        <img src={cartClose} alt="Close cookies" />
      </CloseButton>
    </CookieContainer>
  )
}
