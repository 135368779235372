const getFormattedPrice = (price, locale, currency) => {
  if (locale === 'sv') {
    return `${price} kr`
  }
  switch (currency) {
    case 'sek':
      return `${price} kr`
    case 'eur':
      return `€ ${price}`
    case 'usd':
      return `$ ${price}`
    default:
      return `${price} SEK`
  }
}

const getPrice = (prices, locale, currency) => {
  if (locale === 'sv') {
    return prices.sek;
  }
  return prices[currency];
}

const getCurrency = (locale, currency) => {
  if (locale === 'sv') {
    return 'sek';
  }
  return currency;
}

export { getFormattedPrice, getPrice, getCurrency }
