import React from 'react'

interface BeltData {
  cartOpen: boolean
  cart: unknown[]
  currency: string
}

interface BeltContext {
  data: BeltData
  set: React.Dispatch<React.SetStateAction<BeltData>>
}

export const defaultContextValue: BeltContext = {
  data: {
    cartOpen: false,
    cart: [],
    currency: 'eur',
  },
  set: () => null,
}

export const StoreContext = React.createContext(defaultContextValue)
