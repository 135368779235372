import React, { useEffect } from 'react';

import { StaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components'
import { useLocation } from "@reach/router"

import './footer.scss';
import logo from '../images/logo.svg';
import klarnaLogo from '../images/klarna.svg';
import postnordLogo from '../images/postnord.svg';

import seFlag from '../images/flag-se.png';
import ukFlag from '../images/flag-uk.png';
import { colors, breakpointsDown } from '../styling/constants';

const Flags = styled.div`
  display: flex;
  flex-direction: row;

  @media ${breakpointsDown.tablet} {
    margin-bottom: 2rem;
  }
`

const FlagHolder = styled(Link)`
  padding: 0;
  height: 3rem;
  width: 3rem;
  margin-right: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.shouldBeActive ? colors.darkGray : 'transparent'};
  border-radius: 8px;
  border: 2px solid transparent;
  transition: 0.2s border ease-out;

  &:hover {
    border: 2px solid ${colors.gold};
  }
`

const Flag = styled.img`
  width: 60%;
`

const Footer = ({ locale, menuLinks, footerLinks }) => {
  const location = useLocation()

  useEffect(() => {
    const newPath = location.pathname.replace(`/${locale}`, ``)
  }, [location])

  const getLocationForLocale = (newLocale) => {
    if (locale !== newLocale) {
      if (locale !== 'sv') {
        const newPath = location.pathname.replace(`/${locale}`, ``)
        return newPath
      } else {
        const newPath = `/${newLocale}${location.pathname}`
        return newPath
      }
    } else {
      return location.pathname || '/'
    }
  }

  return (
    <div className="footer-container">
      <div className="footer-content">
        <Link to={locale !== 'sv' ? '/' + locale : '/'}><img className="footer-logo" alt="Belt of Sweden logo" src={logo} width="250" /></Link>
        <div className="footer-menu-wrapper">
          <ul>
            { menuLinks.map((link, i) => (
              <li key={i}>
                <Link to={link.link}>{ link.name }</Link>
              </li>
            ))}
          </ul>
          <ul>
            { footerLinks.map((link, i) => (
              <li key={i}>
                <Link to={link.link}>{ link.name }</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="company-logo-holder">
          <Flags>
            <FlagHolder to={getLocationForLocale('sv')} shouldBeActive={locale === 'sv'}>
              <Flag src={seFlag} alt="Swedish flag" />
            </FlagHolder>
            <FlagHolder to={getLocationForLocale('en')} shouldBeActive={locale === 'en'}>
              <Flag src={ukFlag} alt="British flag" />
            </FlagHolder>
          </Flags>
          <div>
            <img src={postnordLogo} alt="Postnord" className="postnord" width="140" />
            <img src={klarnaLogo} alt="Klarna" width="100" />
          </div>
        </div>
      </div>
    </div>
)};

export default Footer;
