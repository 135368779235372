import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'

import en from '../i18n/en.json';
import sv from '../i18n/sv.json';
import Layout from './layout';

const messages = { en, sv };

const LocaleManager = ({ locale, children }) => (
    <IntlProvider locale={locale} messages={messages[locale]}>
        <Layout locale={locale}>
            { children }
        </Layout>
    </IntlProvider>
)

export default LocaleManager
