import React from 'react'

import './hamburger.scss'

const Hamburger = ({ isOpen }) => (
    <div className={ isOpen ? "hamburger open" : "hamburger"}>
        <span className="hamburger__top-bun"></span>
        <span className="hamburger__bottom-bun"></span>
    </div>
)

export default Hamburger